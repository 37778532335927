<template>
  <div class="app-content">
    <el-card class="box-card" v-loading="loading">
      <div class="clearfix">
        <el-row>
          <el-col :span="24">
            <el-form :inline="true" @submit.native.prevent>
              <el-form-item label="姓名">
                <el-input
                  @change="onQuerySubmit"
                  v-model="query.name"
                  placeholder="输入姓名"
                ></el-input>
              </el-form-item>
              <el-form-item label="职位">
                <el-select
                  v-model="org_id"
                  @change="select"
                  placeholder="全部职位"
                >
                  <el-option
                    v-for="item in org"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onQuerySubmit"
                  >查询</el-button
                >
                <el-button
                  type="primary"
                  @click="userCreate"
                  v-if="permission.apiStaffUserCreate"
                  >添加人员</el-button
                >
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <div class="block">
        <el-row>
          <el-col :span="24">
            <el-table :data="tableData.data" stripe style="width: 100%">
              <el-table-column prop="" label="头像" width="150">
                <template slot-scope="scope">
                  <el-avatar
                    :src="config.storage + scope.row.avatar_url"
                  ></el-avatar>
                </template>
              </el-table-column>
              <el-table-column
                prop="created_at"
                label="创建时间"
                min-width="150"
              >
              </el-table-column>
              <el-table-column prop="name" label="姓名" min-width="100">
              </el-table-column>
              <el-table-column prop="org_name" label="职位" min-width="150">
              </el-table-column>
              <el-table-column prop="mobile" label="电话号码" min-width="150">
              </el-table-column>
              <el-table-column fixed="right" label="操作" min-width="150">
                <template slot-scope="scope">
                  <el-button
                    @click="(drawer = true), getUserPapers(scope.row)"
                    type="text"
                    size="small"
                    v-if="permission.apiStaffUserPapers"
                    >证书配置</el-button
                  >
                  <el-button
                    @click="userDatail(scope.row)"
                    type="text"
                    size="small"
                    >详情</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    @click="deleteItem(scope.row)"
                    v-if="permission.apiStaffUserDelete"
                    >注销</el-button
                  >
                  <el-button
                    type="text"
                    size="small"
                    @click="subscription(scope.row)"
                    v-if="isSelf(scope.row)"
                    >订阅通知</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-pagination
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          background
          :page-size="10"
          @current-change="changeCurrent"
          :current-page="query.page"
          :total="query.total"
        ></el-pagination>
      </div>
    </el-card>
    <!-- 订阅通知 -->
    <el-dialog
      title="请使用微信扫描二维码"
      :visible.sync="showDialog"
      width="300px"
      center
      height="300px"
    >
      <div class="er">
        <qrcode-vue :value="wxUrl" :size="180" level="M" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 证书配置 -->
    <el-drawer
      title="证书配置"
      :visible.sync="drawer"
      direction="rtl"
      :wrapperClosable="false"
      size="500px"
    >
      <div class="addscoll">
        <el-card class="box-card">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <el-form label-width="120px" v-show="userPaper.length < 1">
              <ValidationProvider
                v-for="(item, i) in userPapers.length"
                :key="i"
              >
                <el-form-item :label="'证书' + num[i]"> </el-form-item>
                <ValidationProvider
                  rules="required|Chinese"
                  name="证件编码"
                  v-slot="{ errors }"
                >
                  <el-form-item required :error="errors[0]" label="证件编码">
                    <el-input
                      v-model="userPapers[i].code"
                      placeholder="请输入证件编码"
                    ></el-input>
                  </el-form-item>
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  name="工作岗位"
                  v-slot="{ errors }"
                >
                  <el-form-item required :error="errors[0]" label="工作岗位">
                    <el-input
                      v-model="userPapers[i].job"
                      placeholder="请输入工作岗位"
                    ></el-input>
                  </el-form-item>
                </ValidationProvider>
                <el-form-item label="证件类型">
                  <el-input
                    disabled
                    :value="
                      userPapers[i].type === 1 ? '水质处理证书' : '废气处理证书'
                    "
                  ></el-input>
                </el-form-item>
                <ValidationProvider
                  rules="required"
                  name="开始有效期"
                  v-slot="{ errors }"
                >
                  <el-form-item required :error="errors[0]" label="开始有效期">
                    <el-date-picker
                      value-format="yyyy-MM-dd"
                      v-model="userPapers[i].started_at"
                      type="date"
                      placeholder="选择日期"
                    ></el-date-picker>
                  </el-form-item>
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  name="结束有效期"
                  v-slot="{ errors }"
                >
                  <el-form-item required :error="errors[0]" label="结束有效期">
                    <el-date-picker
                      value-format="yyyy-MM-dd"
                      v-model="userPapers[i].expired_at"
                      type="date"
                      placeholder="选择日期"
                    ></el-date-picker>
                  </el-form-item>
                </ValidationProvider>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="handleSubmit(userPapersUpdate), (index = i)"
                    v-show="
                      userPaper.length < 1 &&
                      permission.apiStaffUserPapersUpdate
                    "
                    >保存配置</el-button
                  >
                  <el-button
                    type="primary"
                    @click="addUserPapers"
                    v-show="
                      userPapers.length === 1 &&
                      userPaper.length === 0 &&
                      permission.apiStaffUserPapersAdd
                    "
                    >添加运维证书</el-button
                  >
                </el-form-item>
              </ValidationProvider>
            </el-form>
          </ValidationObserver>
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <el-form label-width="120px">
              <ValidationProvider v-if="userPaper.length > 0">
                <el-form-item label="添加证书"> </el-form-item>
                <ValidationProvider
                  rules="required|Chinese"
                  name="证件编码"
                  v-slot="{ errors }"
                >
                  <el-form-item required :error="errors[0]" label="证件编码">
                    <el-input
                      v-model="userPaper[0].code"
                      placeholder="请输入证件编码"
                    ></el-input>
                  </el-form-item>
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  name="工作岗位"
                  v-slot="{ errors }"
                >
                  <el-form-item required :error="errors[0]" label="工作岗位">
                    <el-input
                      v-model="userPaper[0].job"
                      placeholder="请输入工作岗位"
                    ></el-input>
                  </el-form-item>
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  name="证件类型"
                  v-slot="{ errors }"
                >
                  <el-form-item required :error="errors[0]" label="证件类型">
                    <el-select
                      v-model="userPaper[0].type"
                      placeholder="选择证件类型"
                    >
                      <el-option label="水质处理证书" :value="1"></el-option>
                      <el-option label="废气处理证书" :value="2"></el-option>
                    </el-select>
                  </el-form-item>
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  name="开始有效期"
                  v-slot="{ errors }"
                >
                  <el-form-item required :error="errors[0]" label="开始有效期">
                    <el-date-picker
                      value-format="yyyy-MM-dd"
                      v-model="userPaper[0].started_at"
                      type="date"
                      placeholder="选择开始有效期"
                    ></el-date-picker>
                  </el-form-item>
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  name="结束有效期"
                  v-slot="{ errors }"
                >
                  <el-form-item required :error="errors[0]" label="结束有效期">
                    <el-date-picker
                      value-format="yyyy-MM-dd"
                      v-model="userPaper[0].expired_at"
                      type="date"
                      placeholder="选择结束有效期"
                    ></el-date-picker>
                  </el-form-item>
                </ValidationProvider>
              </ValidationProvider>
              <el-form-item>
                <el-button
                  type="primary"
                  @click="handleSubmit(userPapersAdd), (index = 0)"
                  v-show="
                    userPaper.length === 1 && permission.apiStaffUserPapersAdd
                  "
                  >提交证书信息</el-button
                >
                <el-button
                  type="primary"
                  @click="addUserPapers"
                  v-show="
                    userPapers.length === 0 &&
                    userPaper.length === 0 &&
                    permission.apiStaffUserPapersAdd
                  "
                  >添加运维证书</el-button
                >
              </el-form-item>
            </el-form>
          </ValidationObserver>
        </el-card>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import QrcodeVue from "qrcode.vue";
import config from "../../../config";
import {
  apiStaffUserList,
  apiStaffUserRoleList,
  apiStaffUserDelete,
  apiStaffUserPapers,
  apiStaffUserPapersAdd,
  apiStaffUserPapersUpdate,
} from "src/api/index";
import md5 from "js-md5";
export default {
  data() {
    return {
      loading: false,
      drawer: false,
      org: {},
      query: {
        name: "",
        org_id: 0,
        page: 1,
        total: 0,
        logotype: "staff",
      },
      org_id: "",
      user_id: 0,
      tableData: {},
      userPapers: [],
      userPaper: [],
      num: ["一", "二"],
      index: 0,
      showDialog: false,
      wxUrl: "",
    };
  },
  components: {
    QrcodeVue,
  },
  mounted() {
    const _this = this;
    let query = JSON.parse(_this.$localStorage.get(md5("query")));
    if (query === null) {
      _this.query = _this.query;
    } else if (query.logotype === "staff") {
      _this.query = query;
      if (query.org_id != 0) {
        _this.org_id = query.org_id;
      }
    } else {
      _this.$localStorage.remove(md5("query"));
    }
    //获取人员职位信息
    apiStaffUserRoleList()
      .then((lists) => {
        _this.org = lists;
        _this.changeCurrent(_this.query.page);
      })
      .catch((err) => {
        _this.error(err);
      });
    _this.pageInit(_this).then(() => {});
  },
  computed: mapState({
    permission: (state) => state.permission,
    url: (state) => state.url,
    config: (state) => state.config,
  }),
  watch: {
    $route: "getData",
  },
  methods: {
    ...mapActions(["pageInit"]),
    //查询人员信息
    onQuerySubmit() {
      this.query.page = 1;
      let route =
        this.$router.history.current.path +
        "?page=" +
        this.query.page +
        "&name=" +
        this.query.name +
        "&org_id=" +
        this.query.org_id;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getData();
      } else {
        this.$router.replace(route);
      }
    },
    //切换分页
    changeCurrent(item) {
      this.query.page = item;
      let route =
        this.$router.history.current.path +
        "?page=" +
        this.query.page +
        "&name=" +
        this.query.name +
        "&org_id=" +
        this.query.org_id;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getData();
      } else {
        this.$router.replace(route);
      }
    },
    //获取人员列表信息
    getData() {
      const _this = this;
      _this.loading = true;
      _this.tableData = {};
      apiStaffUserList(_this.query)
        .then((lists) => {
          _this.loading = false;
          _this.query.total = lists.total;
          _this.tableData = lists;
        })
        .catch((err) => {
          _this.loading = false;
          _this.error(err);
        });
    },
    //获取职位id
    select(item) {
      this.query.org_id = item;
    },
    //跳转人员添加页
    userCreate() {
      this.$localStorage.set(md5("query"), JSON.stringify(this.query));
      this.$router.push("/admin/personnel/staff-add");
    },
    //跳转人员详情页
    userDatail(item) {
      this.$localStorage.set(md5("query"), JSON.stringify(this.query));
      this.$router.push("/admin/personnel/staff-detail/" + item.id);
    },
    //注销内部人员信息
    deleteItem(userid) {
      const _this = this;
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        apiStaffUserDelete({ id: userid.id })
          .then((res) => {
            _this.$message({
              message: "注销成功",
              type: "success",
            });
            _this.changeCurrent();
          })
          .catch((err) => {
            _this.error(err);
          });
      });
    },
    //获取运维证书配置
    getUserPapers(item) {
      const _this = this;
      _this.userPapers = [];
      _this.userPaper = [];
      _this.user_id = item.id;
      apiStaffUserPapers({ id: item.id })
        .then((res) => {
          _this.userPapers = res;
        })
        .catch((err) => {
          _this.error(err);
        });
    },
    addUserPapers() {
      this.userPaper.push({
        code: "",
        expired_at: "",
        id: this.user_id,
        job: "",
        started_at: "",
        type: 1,
      });
    },
    //添加运维证书配置
    userPapersAdd() {
      const _this = this;
      if (
        _this.compareDate(
          _this.userPaper[0].started_at,
          _this.userPaper[0].expired_at
        )
      ) {
        _this.$message.warning("结束时间不能小于开始时间");
        return;
      }
      apiStaffUserPapersAdd(_this.userPaper[0])
        .then((res) => {
          _this.$message({
            message: "添加成功",
            type: "success",
          });
          _this.getUserPapers({ id: _this.user_id });
        })
        .catch((err) => {
          _this.error(err);
        });
    },
    //修改运维证书配置
    userPapersUpdate() {
      const _this = this;
      if (
        _this.compareDate(
          _this.userPapers[_this.index].started_at,
          _this.userPapers[_this.index].expired_at
        )
      ) {
        _this.$message.warning("结束时间不能小于开始时间");
        return;
      }
      _this.userPapers[_this.index].id = _this.user_id;
      apiStaffUserPapersUpdate(_this.userPapers[_this.index])
        .then((res) => {
          _this.$message({
            message: "修改成功",
            type: "success",
          });
          _this.getUserPapers({ id: _this.user_id });
        })
        .catch((err) => {
          _this.error(err);
        });
    },
    // 判断日期大小
    compareDate(dateTime1, dateTime2) {
      var formatDate1 = new Date(dateTime1);
      var formatDate2 = new Date(dateTime2);
      if (formatDate1 > formatDate2) {
        return true;
      } else {
        return false;
      }
    },
    // 错误信息提示
    error(err) {
      if (err.message === "没有操作权限") {
        return;
      } else {
        this.$message.error(err.message);
      }
    },
    subscription(val) {
      const _this = this;
      _this.showDialog = true;
      _this.wxUrl =
        location.href.slice(0, location.href.indexOf(".cn") + 3) +
        "/api_wx/authorize/bind?token=" +
        _this.$localStorage.get(md5("session"));
    },
    isSelf(val) {
      return this.$store.state.user.id == val.id;
    },
  },
};
</script>
<style lang="less" scoped>
.app-content {
  background-color: #ffffff;
}
.el-pagination {
  text-align: center;
  margin-top: 27px;
}
.el-avatar--circle {
  position: relative;
  margin-top: 7px;
}
/deep/ .el-table--small td,
.el-table--small th {
  padding: 0;
}
/deep/ .el-table td,
.el-table th {
  padding: 0;
}
/deep/ .is-leaf {
  padding: 8px 0;
}
.addscoll {
  overflow: auto;
  height: 92vh;
}
/deep/ .el-dialog {
  height: 340px;
  .er {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
